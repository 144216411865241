import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Button,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  Typography,
  Box,
  Modal,
  TableHead,
  TableContainer,
  Paper,
  Dialog,
  Tooltip,
  useTheme,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InputLabel from '@mui/material/InputLabel';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Tab, TabLabel, Table, Tabs,Notification } from "@cambridgeassessment/cambridge-ui";
import { csvGenerator, getComparator, getStatusStyle, isValidCheckpointDate } from "../../utils/utils";
import { candidateListPageLabels } from "../../utils/labels";
import "./customCandidateTable.css";
import DetailedView from "../../containers/DetailedView";
import IneligibleCandidatesView from "../../containers/IneligibleCandidatesView";
import { CANDIDATES_DETAILEDVIEW_TABLE_HEADER,INELIGIBLE_TABLE_HEADER } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setDetailViewActive,setIneligibleViewActive, setIsDoneFilter, setTabValue, setSummaryViewActive, setAlertClick } from "../../redux/actions/actions";
import EnhancedTableHead from "../EnhancedTabelHead";
import EoCheckpointModal from "../../containers/Checkpoints/EoCheckpointModal";
import TeacherCheckPoint from "../../containers/Checkpoints/TeacherModal"
import {mulePostApiInstance,muleApiInstance,DEFAULT_DATE_SPAN,APP_ERROR_CODES} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState,useRef } from "react";
import { table } from "mdast-util-to-hast/lib/handlers/table";
import Checkbox from '@mui/material/Checkbox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import makeStyles, { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Outlet, useOutletContext } from "react-router-dom";
import { setMainFilter,setTableData } from "../../redux/actions/actions";
import CloseIcon from '@mui/icons-material/Close';
import {setApprove,setEoApprove } from '../../redux/actions/actions';
import _ from "lodash";
import DialogContentText from "@mui/material";
import ConfirmationPop from '../ConfirmationPopScreen/index'

// export const DEFAULT_DATE_SPAN={
//   startDate:'2020-04-01',
//   level:"L2"
// }
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CustomCandidateTable = (props) => {
  const[windowWidth,setWindowWidth] = useState(window.innerWidth)
  const {tableHeaders, tableData,eligibilityOverrideCount, getCandidateSummary,getCandidateResultDetails,ineligibleDetails,ineligibleView,ineligibleDataCount,fetchIneligibleDataCount,getIneligibleSummary} = props;
  const navigate = useNavigate();
  const sortedData = tableData.sort((a,b) => a.rsaCandidateNo - b.rsaCandidateNo);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [withdraw,setWithdraw] = useState(null)
  const state = useSelector(fnState=>fnState)
  const [page, setPage] = React.useState(0)
  const [openModal, setOpenModal] = useState(false);
  const [eModal, setEModal] = useState(false);
  const [ModalFlag,setModalFlag] = useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('rsaCandidateNo');
  const [flag,setFlag] = useState(false);
  const [year,setYear] = useState(2023);
  const [rowVal,setRowVal] = useState();
  const [sch,setSch] = useState();
  const notificationRef = useRef();
  const [sucess,setSucess] = useState(false)
  const [errormsg,setErrorMsg] = useState(false);
  const [selectAll,setSelectAll] = useState(false);
  const [selectedCheck,setSelectedCheck] = useState([]);
  const [overrideData,setOverrideData] = useState({})
  const [selectedYear,setSelectedYear] = useState('Select');
  const [rowmsg,setRowMsg] = useState({});
  const [dynamicOptions,setDynamicOptions] = useState();
  const [checkFlag,setcheckFlag] = useState(true);
  const [isSwitchOn,setIsSwitchOn] = useState(false);
  const [selectedRows,setSelectedRows] = useState([]);
  const [user,setUser] = useState();
  const [tstamp, setTstamp] = useState();
  const [subject,setSubject] = useState([]);
  const [err,setErr] = useState();
  const [Api,setApi] = useState(true);
  const[tab,setTab] = useState([]);
  const [Eoflag,setEoFlag] = useState(true);
  const [checkPointStatus,setCheckPointStatus] = useState();
  const [point,setPoint] = useState([])
  const [pointFlag,setPointFlag] = useState(false);
  const [checkedData,setCheckedData] = useState([]);
  const [manualSelection,setManualSelection] = useState([]);
  const centerNumber = state.selectedOrg.orgId;
  const currentYr = 'Summer'+' ' + 2025;
  const [drp,setDrp] = useState(false);
  const [selected,setSelected] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [selectAllFlag,setSelectAllFlag] = useState(true);
  const [isDisabled,setIsDisabled] = useState(false);
  const [isOn,setIsOn] = useState(true);
  const outletProps = useOutletContext();
  const tableDataWithHistory = tableData.map((entry,history) => ({...entry,history: "History"}));
  const [tableDataFilter,setTableDataFilter] = useState(tableData);
  const [time,setTime] = useState(false);
  const [by,setBy] = useState([]);
  const [status,setStatus] = useState();
  const[dbFlag,setDbFlag] = useState(true);
  const[open,setOpen] = useState(false);
  const [sortFlag,setSortFlag] = useState(false)
  const [finalData,setFinalData] = useState([]);
  const [withFlag,setWithFlag] = useState(false)
  const [dataCheck,setDataCheck] = useState()
  // const { candidateDetails } = location.state||{};
  const [reload,setReload] = useState(0);
  const [ineligibleDates,setIneligibleDates] = useState({});
  const [ineligibleTotalDetails,setIneligibleTotalDetails] = useState({});
  const alertRef = useRef();
  const [isShow,setIsShow] = useState(false)
  const [ineligibleActive,setIneligibleActive] = useState(false);
  const [rowIndex,setRowIndex] = useState()
  const [rsaId,setRsa] = useState()
  const [schemaID,setSchemaId] = useState()
  const [yrtxt,setYrTxt] = useState()
  const useStyles = makeStyles({
    checkbox:{
      '&.Mui-checked':{
        '&:hover' :{
        backgroundColor: '#0076BD',
      },
       '&:focus':{
        backgroundColor: '#0076BD',
       },
      },
    },
  });
  const currentyr =  new Date().getFullYear();

  const classes = useStyles();
  var tablerows;
 var newoptions;
//  var startDate
//  var endDate
//   var  rsacandidateno
//    var subjectlevel
//    var qualificationg
//   var  qualificationStatus
//    var certificatingSta]tus
console.log(outletProps,"filter values");

useEffect(()=> {
   setTimeout(() => {
    setIneligibleActive(true);
  }, 1000);
},[])
useEffect(() =>
{
  fetchIneligibleDataCount()
},[state.ineligibleCount])

const getCandidate = (startDate,endDate,rsacandidateno,subjectlevel,qualification,qualificationStatus,certificatingStatus) => {
  return muleApiInstance({
    url: "candidatesSummary",
    params: {
      centreId: state.selectedOrg.orgId,
      startDate: startDate ? startDate : DEFAULT_DATE_SPAN.startDate,
      endDate: endDate ? endDate : DEFAULT_DATE_SPAN.endDate,
      rsaCandidateNumber: rsacandidateno ? rsacandidateno : null,
      subjectLevel: subjectlevel ? subjectlevel : null,
      mainscheme: qualification ? qualification : null,
      qualificationResult: certificatingStatus ? certificatingStatus : null,
      resultFlag: qualificationStatus ? qualificationStatus : null,
    },
  })
  // .then((res) =>
  // {
  //   console.log(res.data,"firstgetcall")

  //   // setTableDataFilter(res.data);
  // })
  // .catch((err) =>{
  //   console.log(err);
  // })
};

useEffect(() => {
getCandidate(
      outletProps.appliedFilters.regStartDateFilter,
      outletProps.appliedFilters.regEndDateFilter,
      outletProps.appliedFilters.searchFieldFilter,
      outletProps.appliedFilters.subjectLevelFilter,
      outletProps.appliedFilters.qualificationFilter,
      outletProps.appliedFilters.qualificationStatus,
      outletProps.appliedFilters.certificatingStatus
    )
    .then((resp) => {
      console.log(resp.data,"initial get call");
      setTableDataFilter(resp.data);
      tablerows = resp.data;

    })
    .catch((err) =>
   {
    console.log(err,"error");
  })
  },[]);

  useEffect(() =>{
    const getCheckpointStatus = () => {
      return muleApiInstance({
        url: "checkPointStatusForCentres",
        params: {
          centreId: state.selectedOrg.orgId,
        },
      })
      .then((res) =>
      {   setDataCheck(res.data)
          setCheckPointStatus(res.data[0].checkPointStatus)
          console.log(res.data[0].subjectAndLevel.value,"value");
          const jsonData = JSON.parse(res.data[0].subjectAndLevel.value)
          console.log(jsonData,"Json");
          const mainarr = jsonData.subjectandlevel.map((row) =>({
         code: row.subjectcode,
         updated: row.updatedBy,
         time: row.updatedOn,
        }));
        setPoint(mainarr);
        setPointFlag(true);
        setManualSelection([])
          // setPoint(jsonData.subjectandlevel.map((row) => row.subjectcode))
      })
         .catch((err)  =>
      {
        setPoint([]);
        setManualSelection([])
          console.log(err);
      })
     
    };
    getCheckpointStatus();
  },[state.appr,state.eoappr]);

  useEffect(() =>{
    const getCheckSubjectQual = () => {
      return muleApiInstance({
        url: "subjectLevelAndQualification",
        params: {
          centreId: state.selectedOrg.orgId,
          certificationStatus: currentYr,
        },
      })
      .then((res) =>
      {
          console.log(res.data.data,"Checkpoint");
          setTab(res.data.data)
          setSubject(res.data.data)
          // setCheckPointStatus(res.data[0].checkPointStatus)
      })
         .catch((err)  =>
      {
          console.log(err);
      })
    };
    getCheckSubjectQual();
  },[state.appr,state.eoappr]);

  const DateConv = () =>
{
const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];
const currentDate = new Date();
const options = {timeZone: 'Europe/London'}
const uktime = new Date(currentDate.toLocaleString('en-US',options));
const yr = new Date(uktime).getFullYear();
const day = new Date(uktime).getDate();
const month = new Date(uktime).getMonth();
const hours = new Date(uktime).getHours();
const minutes = new Date(uktime).getMinutes();
const seconds = new Date(uktime).getSeconds();
return[`${months[month]} ${day}, ${yr} ${hours}:${minutes}:${seconds}`]
}

const tableDataWithPopId = tab.map((entry,index) => ({...entry,id: index}));
console.log(tab,"state Data")

useEffect(()=>{
  ineligibleDetails();
},[state.ineligibleViewActive])

useEffect(()=>
{
  getCandidateResultDetails();
},[state.detailViewActive])

useEffect(()=>{
  if(state.appr === true)
  {
    setOpen(true);
  }
  else{
    setOpen(false);
  }
   },[state.appr])
  
   useEffect(()=>{
    if(state.eoappr === true)
    {
      setOpen(true);
    }
    else{
      setOpen(false);
    }
     },[state.eoappr])

     const handleClosePop = () =>
     {
       setErr('');
       setOpen(false);
       dispatch(setApprove(false));
       dispatch(setEoApprove(false));
     }
     useEffect(() =>
     {
       const newData = selected.map((Subcode) =>
       {
         const desc =  tableDataWithPopId.filter((row) => row.subjectlevelcode === Subcode)
         const matchingPoint = point.find((item) => item.code === Subcode);
         const overrideItem = manualSelection.includes(Subcode)
         console.log(desc,"boolean")
         return {
           subjectcode: Subcode,
           subject:  desc.length > 0 ? desc[0].subjectlevel : '',
           updatedBy: matchingPoint && overrideItem === false  ? matchingPoint.updated : state.userInfo.email,
           updatedOn: matchingPoint && overrideItem === false ? matchingPoint.time : DateConv()
         };
       });
       setCheckedData(newData);
       
     },[selected,point])
     
     console.log(point,"PointData");
     const renderStatus = (selected,subjects) =>
{
 let subjcount  =  subjects.length;
 const allSubjectCodes = subjects.map((item) =>  item.subjectlevelcode);
 let allselected = allSubjectCodes.every(r => selected.includes(r));

  if(subjcount === 0 || allselected)
  {
    return "Ready to submit"
  }
  else if(subjcount > 0 && selected.length >0 && !allselected)
  {
    return "In progress"
  }
  else{
    return "Not started"
  }
}
const findStatus = (selected) =>
{
  const status = renderStatus(selected,tab);
  
  if(status === "Ready to submit")
  {
    return "A"
  }
  else if(status === "In progress")
  {
    return "P"
  }  else{
    return "I"
  }

}
const handleSubmit = () =>
{
  if(checkPointStatus !== 'C')
  {
  const filteredData = tab.filter((item) => selected.includes(item.subjectlevelcode))
  console.log(filteredData,"rowdata");
  const CheckStatus =  findStatus(selected)
  const selectedSubjectCode =  checkedData.map((row) => ({
    subjectcode: row.subjectcode,
    description: row.subject,
    updatedBy: row.updatedBy,
    updatedOn: row.updatedOn,
   }))
   const selectedSubject = {
    subjectandlevel : selectedSubjectCode
   }
  mulePostApiInstance({
    url: "checkPointStatusForCentres",
    data: [
       {
        centreId: state.selectedOrg.orgId,
        modifiedBy: handleSplChar(state.userInfo.email),
        checkPointStatus: CheckStatus,
        subjectAndLevel: selectedSubject,
      }
     ],
  })
    .then((_resp) => {
      console.log(_resp)
    })
    .catch((_err) => {
     console.log(_err)
    });
  
    setOpen(false);
    dispatch(setEoApprove(false));
    dispatch(setApprove(false));
  }
  else{ 
    setTimeout(()=>
  {
    setErr("Checkpoint Declaration was already completed!")
  },3000);
  console.log("AlreadySubmitted");
  }
}

const handleEoSubmit = () =>
{
  const filteredData = tab.filter((item) => selected.includes(item.subjectlevelcode))
  console.log(filteredData,"rowdata");
  const CheckStatus =  'C';
  const selectedSubjectCode =  checkedData.map((row) => ({
    subjectcode: row.subjectcode,
    description: row.subject,
    updatedBy: row.updatedBy,
    updatedOn: row.updatedOn,
   }))
   const selectedSubject = {
    subjectandlevel : selectedSubjectCode
   }
  mulePostApiInstance({
    url: "checkPointStatusForCentres",
    data: [
       {
        centreId: state.selectedOrg.orgId,
        modifiedBy: handleSplChar(state.userInfo.email),
        checkPointStatus: CheckStatus,
        subjectAndLevel: selectedSubject,
      }
     ],
  })
    .then((_resp) => {
      console.log(_resp)
    })
    .catch((_err) => {
     console.log(_err)
    });
    setEoFlag(false)
    setOpen(false);
    dispatch(setEoApprove(false));
  }

const handleCheckBox = (rowId,Subjectcode,SubjectLevel) =>
{
  const isManuallyChecked = manualSelection.includes(Subjectcode);
  const isChecked = selected.includes(Subjectcode)

if(isChecked)
{
  setSelected((prev) => prev.filter((val) => val !== Subjectcode))
  setManualSelection((prev) => prev.filter((val) => val !== Subjectcode))
}
else{
  setSelected((prev) => [...prev,Subjectcode])
  if(!isManuallyChecked)
  {
  setPointFlag(false);
  setUser(state.userInfo.email)
  setTstamp(DateConv())
  setManualSelection((val) => [...val,Subjectcode])
  }
}
setOverrideData((prev) =>
{
  const updatedData = {...prev};
  delete updatedData[Subjectcode];
  return updatedData;
})
};
const handleSelectAll = () =>
{
  if(selected.length === tab.length)
  {
    setSelected([]);
    setManualSelection([])
  }
  else{
    const allSubjectCodes = tab.map((item) =>  item.subjectlevelcode);
    setSelected(allSubjectCodes)
    setManualSelection(allSubjectCodes)
    setUser(state.userInfo.email)
    setTstamp(DateConv())
  }
}
useEffect(() =>
{
  setSelected(point.map((row) => row.code))
},[point])
  
console.log(tablerows,"respData")
// const getCandidateHistory = (rsacandidateno, subjectlevel) => {
//   setTime(true);
//   setOpen(true);
//   return muleApiInstance({
//     url: "estimatedCompletionDateHistory",
//     params: {
//       centreId: state.selectedOrg.orgId,
//       ocrCandidateNumber: rsacandidateno ? rsacandidateno : null,
//       mainScheme: subjectlevel ? subjectlevel : null,
//     },
//   })
//   .then((resp)  => {
//      console.log(resp.data,"response");
//     //  let arr = resp.data;
//     setBy(resp.data)
//     // console.log(final,"fetching");
//   })
//   .catch((err) =>
//  {
// console.log(err);
// })
// };

console.log(by,"updatedtime");
const handleClose = () =>
{
  setTime(false);
  setOpen(false);
}
  
  
    const renderComponentBody = () => {
      return <Outlet context={{ ...outletProps }} />;
    };
  
    console.log(tableData,"propsTabledata");

    // const [drop,setDrop] = useState(Array.from({length: tableData.length},(_,index) => ({id: index ,selection: 'option1'}))
  // );
  // const uniqueStatus = Array.from(
  //   new Set(tableData.map((candidate) => candidate.certificationStatus))
  // )
  useEffect(() =>
  {
    dispatch(setTableData(tableData));
  },[])
  
// useEffect(() =>
// {
//   const fetchData = async () =>
//   {
//     const result = await getCandidateResultDetails()
//   }
// })

  const tableDataWithId = tableDataFilter.map((entry,index) => ({...entry,id: index}));
  console.log(tableDataWithId,"iddata")
  // const tableDataWithHistory = tableData.map((entry,history) => ({...entry,history: "history"}));
  console.log(tableDataWithHistory,"history");
  
  const [additionalData,setAdditionalData] = useState(() => {
    const intialData = {};
    for(const rowData of tableDataWithId)
    {
      intialData[rowData.id] = {
        selectedButton: null,
      };
    }
    return intialData
  })

  const [initialColor,setInitialColor] = useState(() => {
    const intialData = {};
    for(const rowData of tableDataWithId)
    {
      intialData[rowData.id] = {
        defltColr: null,
      };
    }
    return intialData;
  })
  const [checkData,setCheckData] = useState(() => {
    const intialData = {};
    for(const rowData of tableDataWithId)
    {
      intialData[rowData.id] = {
        selectedCheckList: null,
      };
    }
    return intialData;
  })

 

  const [selectAllCheck,setSelectAllCheck] = useState(() => {
    const intialData = tableDataWithId.map((rowData) =>({
      selectedAllList:null,
    }));
    return intialData;
  })
  console.log(selectAllCheck,"selectAll")
  // console.log(additionalData,"format");
  const drpOptions = Array.from(
    new Set(tableData.map((candidate) => candidate.certificationStatus))
  )
  const uniqueStatus = tableDataFilter.map((candidate) => candidate.certificationStatus)

  const years = uniqueStatus.map((row) => {
    const splitrow =  row.split(' ');

    return splitrow.length > 1 ? splitrow[1] : row;

  })
  console.log(years,"new Data");
  const [drop,setDrop] = useState(tableData.map((candidate,index) => ({
    id:index,
    selection: candidate.certificationStatus,
    isDropFocus: false,
    dynamic: [],
    previousSelection: candidate.certificationStatus,
  })))
  // console.log(drop,"what is inside drop");

  const unselectedBox = () =>
  {
    setcheckFlag(false);
    return null;
  }
  const selectedBox = (idx) =>
  {
      return "confirmed";
  }

  useEffect(() =>
{
if(yrtxt === 'Withdraw' && tableData[rowIndex].certificationStatus !== yrtxt)
{
 setOpenModal(true)
}
if(yrtxt === 'Eligible' && tableData[rowIndex].certificationStatus !== yrtxt)
{
 setEModal(true)
}
if(reload > 0 && yrtxt !== 'Withdraw' && yrtxt !== 'Eligible'&& tableData[rowIndex].certificationStatus !== yrtxt)
{
  handleUpdate()
}
},[reload])


const handleSplChar = (char) =>
{
 let regex = /'/
 if(regex.test(char))
 {
   console.log("splchar")
   char = char.replace("'","")
   console.log(char,"trimmed")
   return char
 }
 else{
   return char
 }
}

  const handleUpdate = () =>
  {
  if(Api)
  {
    setSortFlag(true)
    mulePostApiInstance({
      url: "estimatedCompletionDate",
      data: [
        {
          centreId: state.selectedOrg.orgId,
          ocrCandidateNumber: rsaId,
          mainScheme: schemaID,
          certificationStatus:yrtxt,
          submittedBy: handleSplChar(state.userInfo.name),
        },
      ],
    })
      .then((_resp) => {
        console.log(_resp,"response");
         
         getCandidateSummary();
      
        getCandidate(
          outletProps.appliedFilters.regStartDateFilter,
          outletProps.appliedFilters.regEndDateFilter,
          outletProps.appliedFilters.searchFieldFilter,
          outletProps.appliedFilters.subjectLevelFilter,
          outletProps.appliedFilters.qualificationFilter,
          outletProps.appliedFilters.qualificationStatus,
          outletProps.appliedFilters.certificatingStatus
        )
        
          .then((resp) => {
                console.log(resp.data,"Api data");
                const data = resp.data;
                const sortedData = data.sort((a,b) => a.rsaCandidateNo - b.rsaCandidateNo);
                console.log(sortedData,"Finalsorted");
                // setFinalData(sortedData)
                setTableDataFilter(resp.data);
                setAdditionalData((prev) =>
              {
                const newData = {...prev};
                for(const id of Object.keys(newData))
                {
                  newData[id].selectedButton = null;
                }
                return newData;
              })
             
           setSucess(true);
             
          })
          .catch((err) => {
             console.log(err);
                   });
          fetchIneligibleDataCount();
    
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg(true);
      });
    }
    else{
    const postData = selectedRows.map(row =>({
          centreId: state.selectedOrg.orgId,
          ocrCandidateNumber: row.rsaCandidateNo,
          mainScheme: row.specificationCode,
          certificationStatus: row.certificationStatus !== 'Withdraw' && row.certificationStatus !== 'Eligible' ? `Summer` +" "+row.certificationStatus : row.certificationStatus,
          submittedBy: handleSplChar(state.userInfo.name)
    }))
    mulePostApiInstance({
      url: "estimatedCompletionDate",
      data: postData,
    })
    .then((_resp) => {
      console.log(_resp,"response");
      getCandidate(
            outletProps.appliedFilters.regStartDateFilter,
            outletProps.appliedFilters.regEndDateFilter,
            outletProps.appliedFilters.searchFieldFilter,
            outletProps.appliedFilters.subjectLevelFilter,
            outletProps.appliedFilters.qualificationFilter,
            outletProps.appliedFilters.qualificationStatus,
            outletProps.appliedFilters.certificatingStatus
          )
          
            .then((resp) => {
                  console.log(resp.data,"Api data");
                  const data = resp.data;
                  const sortedData = data.sort((a,b) => a.rsaCandidateNo - b.rsaCandidateNo);
                  console.log(sortedData,"finalsorted");
                  // setFinalData(sortedData);
                  setAdditionalData((prev) =>
               {
                const newData = {...prev};
                for(const id of Object.keys(newData))
                {
                  newData[id].selectedButton = null;
                }
                return newData;
              })
                  setTableDataFilter(resp.data);
            })
            .catch((err) => {
               console.log(err);
            });
            fetchIneligibleDataCount();
      getCandidateSummary(null,null,null,null,null,yrtxt);
     
    // getPagination()
    // setSelectAll(false);
    // setSelectAllCheck(selectAllCheck.map((row) => ({...row,selectedAllList: null})));
      Object.keys(checkData).forEach(key =>
    {
      checkData[key].selectedCheckList = null;
    })
    setSelectAllFlag(true);
    // getPagination()
      setSucess(true);
    })
    .catch((err) => {
      console.log(err);
      setErrorMsg(true);
    });
  }
}
console.log(selectedCheck,"??");
console.log(finalData,"Final Data")
  const fetchDynamic = (rsa,schemaID) =>
  {
    
    let arr = tableDataFilter.find(item => item.rsaCandidateNo === rsa && item.specificationCode === schemaID);
    console.log(arr,'array result')
    const selcArr = Object.entries(arr);
    
    console.log(selcArr,"arr");
    const qualification = selcArr.find(([key]) => key  === 'qualification');
    const level = selcArr.find(([key]) => key  === 'level');
    console.log(level[1]);
    const dteOfReg = selcArr.find(([key]) => key  === 'dateOfRegistration');
    console.log(dteOfReg[1]);
    var ocrtxt = qualification ? qualification[1] : undefined;
    let lvl = ocrtxt.split(' ');
    let resut = [lvl[1],lvl[2]];
    let yearr = new  Date(dteOfReg[1]).getFullYear();
    let monthh = new Date(dteOfReg[1]).getMonth();
    console.log(yearr,monthh,"year and month");
    let final = resut.join(" ");
    // console.log(final,'final result');
    // console.log(lvl,'level')
    // let dt = DEFAULT_DATE_SPAN.startDate;
    const yr  = new Date().getFullYear();
    const month = new Date(dteOfReg[1]).getMonth();
    const esdt = (yr) + Number(level[1]) - (month <= 8 ?  2 : 1);
    return [`${yr}`, `${yr + 1}`, `${yr + 2}`, `${yr + 3}`, `Withdraw`];
  }
  
const getSelectedRows = () => {
  return tableDataWithId
  .filter((item) => selectedCheck.includes(item.id))
  .map((item) => ({
    ...item,
    certificationStatus : selectedYear
  }));
}

  useEffect(() => {
    const selectedCheckBox = getSelectedRows()
    setSelectedRows(selectedCheckBox);

  },[selectedCheck]);

  const selectFinal = getSelectedRows();
  console.log(selectFinal,"FinalTable")
  console.log(selectedCheck,"multiplerows")
  const handleCheckboxChange = (rowId,rsa,schemaID) =>
  {
    const selectedRow = tableDataWithId.find(item => item.rsaCandidateNo === rsa && item.specificationCode === schemaID);
    console.log(selectedRow,"one row");
    const isSelected = selectedRows.some(row => row.id === rowId);
    if(isSelected)
    {
      console.log("working")
      setSelectedRows(selectedRows.filter(row => row.id !== rowId));
    }
    else{
      console.log("going inside");
      console.log("selectedyear",selectedYear)
      setSelectedRows((prev) =>[
        ...prev,
        {
          ...selectedRow,
          certificationStatus: selectedYear,
        },
      ]);
    }

    let updatedRowId = page === 0 ? rowId : rowId + (page * rowsPerPage)
   
    if(selectedCheck.includes(updatedRowId))
    {
      setSelectAllFlag(true);
     const val = unselectedBox();
      setCheckData((prev) => ({
        ...prev,
        [updatedRowId]:{
          ...prev[updatedRowId],
          selectedCheckList: val,
        },
      }));
      setSelectedCheck(selectedCheck.filter(id => id !== updatedRowId));
    }
  else{
    console.log("checkedboxes",checkData);
    const newval = selectedBox(rowId);
    setCheckData((prev) => ({
      ...prev,
      [updatedRowId]:{
        ...prev[updatedRowId],
        selectedCheckList: newval,
      },
    }));
    setSelectedCheck([...selectedCheck,updatedRowId])
  }

  }

  console.log(selectedCheck,"checkselected")
  const handleDropdownChange = (e) =>
  {
    setDbFlag(false);
    setSelectedYear(e.target.value);
    
    const newyr = e.target.value;
    // console.log(newyr,"selected value");
    unselectedBox();
    selectedCheck.forEach((rowId) => {
    setCheckData((prev) => ({
      ...prev,
      [rowId]:{
        ...prev[rowId],
        selectedCheckList: "confirmed" ,
      },
    }));
  })
  console.log(selectedRows,"we got rows");
  const updateRows = selectedRows.map((row) => {
    return{
      ...row,
      certificationStatus: newyr,
    }
  });
  setSelectedRows(updateRows);
console.log('updatedRows',updateRows);
    setSelectedYear(e.target.value);
  }

  console.log("rows",selectedRows);
  console.log("selectedyr",selectedYear);

  const handleload = (details,idx,schemaid) =>
  {
    // console.log(idx);
    // console.log(additionalData[idx].selectedButton,"problem");
    newoptions = fetchDynamic(details,schemaid);
    console.log(newoptions,"options");
  }
  
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const handleRowId = () =>
{
  if(page === 0)
  {
    return rowsPerPage
  }
  else{
    return page * rowsPerPage
  }
}

console.log(reload,"Reload")
const handleClick = (val,rsa,rowId,buttonIndex,schema) =>
  {
    setSucess(false);
    // setModalFlag(false)
    
   console.log("click")
    setReload(prev => prev + 1) 

   let updatedRowId = page === 0 ? rowId : rowId + (page * rowsPerPage)
   setRowIndex(updatedRowId)
    // setOpenModal(true)
    let timeOut = buttonIndex > 3 ? 7000 : 4500
    console.log(buttonIndex,"buttonIndex");
    console.log(schema,"schemaId");
    console.log(rsa,"rsa")
    console.log(rowId,"rowId") 
    const yrtxt = val ? val === "Withdraw" || val === "Eligible" ? val : `Summer` +" "+ val : "";
    console.log(val,yrtxt,tableData.certificationStatus,"valuesromco")
    setYrTxt(yrtxt)
    setSchemaId(schema)
    setRsa(rsa)
    setAdditionalData((prev) => ({
      ...prev,
      [updatedRowId]:{
        ...prev[updatedRowId],
        selectedButton: buttonIndex,
      },
    }));
    setRowMsg((prev) =>({
      ...prev,
      [updatedRowId]:true
    }));
    setTimeout(()=>
  {
    setRowMsg((prev) =>
  ({
    ...prev,
    [updatedRowId]:false,
  }))
  },timeOut);
    
    // if(tableData[rowId].certificationStatus !== yrtxt && buttonIndex !== 4){
    //   handleUpdate(yrtxt,rsa,schema)
    // }
}

const handleSingleWithdraw = (txt,rsaId,schemaId) =>
{
  handleUpdate(txt,rsaId,schemaId)
}

const handleWithdrawConfirm = () =>
{
  if(flag === true)
  {
   setFlag(false)
   setApi(true)
   handleUpdate()
   setIsSwitchOn(false)
   setIsClicked(false)
   setIsDisabled(false)
   setSelectedCheck([])
   setSelectAll(false)
   setOpenModal(false);
  }
  else{
    setSucess(true)
    handleUpdate()
    setOpenModal(false);
  }
}

const handleEligibleConfirm = () =>
{
  if(flag === true)
  {
   setFlag(false)
   setApi(true)
   handleUpdate()
   setIsSwitchOn(false)
   setIsClicked(false)
   setIsDisabled(false)
   setSelectedCheck([])
   setSelectAll(false)
   setEModal(false);
  }
  else{
    setSucess(true)
    handleUpdate()
    setEModal(false);
  }
}

const handleWithdrawCancel = () =>
{
  setWithdraw(null)
  console.log('falsevalue')
  setOpenModal(false);
}

const handleEligibleCancel = () =>
{
  setWithdraw(null)
  console.log('falsevalue')
  setEModal(false);
}

  console.log(Object.keys(additionalData).length,"buttonselection")
//  console.log(additionalData,"After selection")
   let selectedT = tableData.find(item => item.rsaCandidateNo === sch)
  //  console.log(selected,typeof(selected));
   if(selectedT !=  null)
   {
   const selcArr = Object.entries(selectedT);
  //  console.log(selcArr,"Array");
  //  console.log(state.selectedOrg,"org")
   const speci = selcArr.find(([key]) => key  === 'specificationCode');
   var schema = speci ? speci[1] : undefined;
  //  console.log(schema,"specification id")
   const ocr = selcArr.find(([key]) => key  === 'rsaCandidateNo');
   var ocrno = ocr ? ocr[1] : undefined;
  //  console.log(ocrno,"rsa id");
  }

 
  const handleDrop = (event,rowIndex) =>
  {
    const value = event.target.value;
    setDrop((prev) =>{
      if(Array.isArray(prev)){
        return prev.map((row,index) =>
             index  === rowIndex ? {...row,isDropFocus:false, selection: value} : row
    );
}
return prev;
    });
  //   const dynamicdata = fetchDynamic();
  //   setDrop((drp) =>
  // {
  //   drp.map((row,idx) => (idx === rowIndex ? {...row,  dynamic: dynamicdata}:row))
  // })
    const updatedTabledata = [...tableData];
    updatedTabledata[rowIndex].certificationStatus = value;
  //  handleUpdate(text,ocrno,schema,rowIndex,value);
  };
  // console.log("checking focus",drop)

  const handleRequestSort = (_event,property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
console.log(rowsPerPage,"rowsperpage")
console.log(page,"pages")
  const onDownloadClick = () => {
    let jsonData = state.detailViewActive ? props.detailedView : (state.ineligibleViewActive ? props.ineligibleView : tableDataFilter);
    let csv = csvGenerator(
      jsonData,
      state.detailViewActive ? CANDIDATES_DETAILEDVIEW_TABLE_HEADER : (state.ineligibleViewActive ? INELIGIBLE_TABLE_HEADER :   tableHeaders),
      state.detailViewActive ?'candidateDetailed': (state.ineligibleViewActive ? 'ineligibleCandidates' :'candidateSummary')
    );
    const link = document.createElement("a");
    let blob = new Blob([csv]);
    let url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${
      state.detailViewActiveg
        ? candidateListPageLabels.detailedFileLabel
        : (state.ineligibleViewActive ? candidateListPageLabels.ineligibleFileLabel : candidateListPageLabels.fileLabel)
    }_${dayjs().format("DD/MM/YYYY")}.csv`; //Name the file here
    link.click();
  };


 const onBulkClick =() =>
 {
  setFlag(true);
  setApi(false);
  setIsClicked(true);
 }
 const onMulltipleUpdateClick =() =>
 {
   if(selectedYear === 'Withdraw') 
   {
     setOpenModal(true) 
   }
   else if(selectedYear === 'Eligible')
   {
     setEModal(true)
   }
  else{
   setFlag(false)
   setApi(true)
   handleUpdate()
   setIsSwitchOn(false)
   setIsClicked(false)
   setIsDisabled(false)
   setSelectedCheck([])
   Object.keys(additionalData).forEach(key =>
   {
      additionalData[key].selectedButton = null;
    })
   handleSelectAllChange(false);
  }
 }
  console.log(tableData,"beforeSorting");
  console.log(tableDataFilter,"datafilter")
  const updation = tableDataFilter.map((candidate) => candidate.certificationStatus)
  console.log(updation,"sortyear");
  const sort1 = tableDataFilter.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const sort2 = tableDataFilter.sort(getComparator(order, orderBy));
  console.log(sort1,"sorteddata");
  console.log(sort2,"sorteddata2");
  const updation2 = sort2.map((candidate) => candidate.certificationStatus)
  console.log(updation2,"sortyears");
  
  const sortyrs = updation2.map((row) => {
    const splitrw =  row.split(' ');

    return splitrw.length > 1 ? splitrw[1] : row;

  })
  console.log(sortyrs,"years")
  const getPagination = () => {
  console.log(tableDataFilter,"tablerws");
  console.log(tableDataFilter,"After updation")
  console.log("After multipleUpdate");
    if (tableDataFilter.length > 0) {
      console.log(tableDataFilter,"inside updation")
      return rowsPerPage > 0
        ?   sort1
        :   sort2
        
    } else return [];
  
  };

  console.log(tableDataFilter,"finalTable")

  const handleBlur = (index) =>
 {
   const currSelection = drop[index].selection;
   const prevSelection = drop[index].selection;
  //  console.log("blur going");
     setDrop((drp) =>
    drp.map((row,idx) => (idx === index ? {...row,isDropFocus:false,selection: currSelection === '' ? prevSelection : currSelection}: row))
 );
 }
 const handleChangeYear = (e) =>
 {
   setYear(e.target.value);
 }
 const handleSelectAllChange = (checked) =>
 { 
  console.log(checked,"Checked value");
   const allSelected = selectedRows.length === tableDataWithId.length;
 
  if(checked === true)
  {
   const allCheckboxes =  Array.from({length: tableDataFilter.length},(_,index) =>  index);
   setSelectedCheck(allCheckboxes);
    setSelectAllFlag(false)
    setSelectAllCheck(selectAllCheck.map((row) => ({...row,selectedAllList: "confirmed"})));
   const updatedCheck = selectAllCheck.map((row) => ({...row,selectedAllList: "confirmed"}))
  //  console.log(updatedCheck,"updated");
     if(allSelected)
       {
        setSelectedRows([]);
       }
   else{
  console.log("yes")
   }
  }
  else{
    setSelectAllFlag(true);
    setSelectedCheck([]);
    setSelectAllCheck(selectAllCheck.map((row) => ({...row,selectedAllList: null})));
    setSelectedRows([]);
    setSelectedCheck([])
    Object.keys(checkData).forEach(key =>
      {
        checkData[key].selectedCheckList = null;
      })
  }
 setSelectAll(checked);
 }
console.log(selectAllCheck,"updated")
console.log(selectedRows,"updatedcheckbox");
 const handleSwitchChange = () =>
 {
   setIsSwitchOn(!isSwitchOn);
   if(!isSwitchOn)
   {
     onBulkClick();
     setIsDisabled(true)
     setIsSwitchOn(true);
   }
   else{
    setApi(true);
    setSelectedCheck([])
    setSelectAll(false);
    setSelectAllFlag(true)
    console.log(checkData,"checkingdata");
     setIsSwitchOn(false);
     setFlag(false);
     setIsDisabled(false)
   }
 }
console.log(tableDataFilter,"inital Data");
console.log(tableData,"Anychanges");
console.log(additionalData,"buttonIndexSelection")

const styles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#ffcccc',
    color: 'black',
    borderRadius: '4px',
    padding: '8px',
    fontSize: '14px',
    maxWidth: '200px', 
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.8)',
  },
  customArrow:{
    '&:before': {
    backgroundColor: '#ffcccc', 
  }}
}));

const tooltipClasses = styles();

const getTotalIneligibleData = () => {
  return muleApiInstance({
    url: "candidatesSummary",
    params: {
      centreId: state.selectedOrg.orgId,
      qualificationResult: academicYear ,
      resultFlag: "Not Eligible"
    },
  });
};

const fetchTotalIneligibleData = () =>  getTotalIneligibleData(
)
  .then((resp) => {
    setIneligibleTotalDetails(resp.data);
  })
  .catch((err) => {
    catchApiError(err)
  });

const getIneligibleDates = () => {
  return muleApiInstance({
    url: "adminDateForCentres",
      params:{
        name:'ineligible'
      }
    
  });
};

const catchApiError = (err) => {
  console.log(err);
  navigate("/error", {
    state: { code: APP_ERROR_CODES.apiFailed },
  });
};
const handleAlert = () => {
  dispatch(setAlertClick(true));
  fetchIneligibleDataCount()
   dispatch(setTabValue('3'));
   dispatch(setIneligibleViewActive(true));
    dispatch(setDetailViewActive(false)) ;
     dispatch(setSummaryViewActive(false));
     navigate("/dashboard/candidates") ;
    }
console.log(ineligibleDataCount,"DataChecking")

useEffect(()=> {
  fetchTotalIneligibleData();
  getIneligibleDates().then((resp) => {
    console.log(resp.data,'ineligible resp')
    if(resp){
    setIneligibleDates( {
      ineligibleStartDate: resp?.data[0]?.startDate,
      ineligibleEndDate: resp?.data[0]?.endDate,
    }
  )
}
})
  .catch((err) => {
    catchApiError(err);
  })
},[])
console.log(ineligibleDates,'ineligible')
const getAcademicYear = (date) =>  {
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();
  return currentMonth >= 8 ? date.getFullYear() + 1 : date.getFullYear()
}

const today = new Date(); 
const academicYear = `Summer ${getAcademicYear(today)}`;
console.log('such data',props.tableData.map((candidate) => candidate.certificationStatus === academicYear))

const handleTabChange = (event, newValue) => {
  dispatch(setTabValue(newValue));
};

const handleReasonDesc = (str) =>
{
  switch(str)
  {
    case "No activity":
    return "No unit achievements, unit claims or examined unit entries"
    break
    case "On Track with results pending":
    return "Aggregated GLH equal or greater to required GLH + all units listed as Mandatory have an agreed unit status against them (everything apart from not entered/not claimed/not achieved) but where some unit status remain at pending"
    break;
    case "Absent from mandatory examined unit":
    return "Candidate absent from at least one mandatory examined unit"
    break;
    case "Mandatory unit not yet achieved":
    return "At least one mandatory unit is missing"
    break;
    case "Not enough guided learning hours (GLH)":
    return "GLH achieved or in progress is less than the total GLH required"
    default:
     return '';
  }
}
const ineligibleNumberCount = () =>
{
return eligibilityOverrideCount.length;
}

 const tableDataContent = (
 getPagination().map((candidate, idx) => {
  const reasons = candidate.ineligiblereason.split(';');
    return ( 
        <TableRow
          key={Number(candidate.rsacandidateno + idx)}
        > 
        <TableCell padding="normal">
            {candidate.centreCandidateNo}
          </TableCell>
          <TableCell className="light-blue-color">
            <Link
              to={`${candidate.rsaCandidateNo}`}
              state={{ candidateDetails: candidate }}
              className={"text-decoration-none"}
            >
              {candidate.rsaCandidateNo}
            </Link>
          </TableCell>
          <TableCell className="light-blue-color">
            <Link
              to={`${candidate.rsaCandidateNo}`}
              state={{ candidateDetails: candidate }}
              className="text-decoration-none"
            >{`${candidate.candidateFirstname}`}</Link>
          </TableCell>
          <TableCell className="light-blue-color">
            <Link
              to={`${candidate.rsaCandidateNo}`}
              state={{ candidateDetails: candidate }}
              className="text-decoration-none"
            >{`${candidate.candidateLastname}`}</Link>
          </TableCell>

          <TableCell>{candidate.qualification}</TableCell>
          <TableCell>{candidate.specificationCode}</TableCell>
          <TableCell>{candidate.expectedGlh}</TableCell>
          <TableCell>{candidate.actualGlh}</TableCell>
          {state.selectedOrg.role === 'EXAMS_OFFICER' ?
          <TableCell>
      {sucess ? rowmsg[idx] && (<Stack sx={{ width: '100%' }} spacing={2}><Alert severity="success">Update successful</Alert>
    </Stack>) : ''}
      {errormsg ? rowmsg[idx] && (<Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">Update unsuccessful</Alert></Stack>) : ''}
          <div className = "wrap"> <div style={{width:'40px'}}><Checkbox style={{display: flag ? 'inline-block' : 'none'}} checked={selectedCheck.includes(page === 0 ? idx : idx + (page * rowsPerPage))} onChange={() => handleCheckboxChange(idx,candidate.rsaCandidateNo,candidate.specificationCode)}
          inputProps={{'aria-label':'controlled'}}
          classes={{
            root: classes.checkbox,
          }} /> </div> 
          <div style={{ width: 320, height: 80, position: 'relative', background:'#D5E9F8', borderRadius: 40, border: '1px solid #0076BD', display: 'block', padding: '0 5px' }}>
          <div style={{ marginLeft: '78px'}}><b>Summer</b></div>
          <div style={{ marginTop:'0px' ,display: 'flex', justifyContent:'space-between', alignItems: 'center', padding: '0 5px' }}>
           {handleload(candidate.rsaCandidateNo, idx, candidate.specificationCode)}
             {newoptions.map((val,buttonIndex) => (        
            <button  onClick={() => handleClick(val,candidate.rsaCandidateNo,idx,buttonIndex,candidate.specificationCode)} 
            style={{ background:  sortyrs[page === 0 ? idx : idx + (page * rowsPerPage)] === val &&  !selectedCheck.includes(page === 0 ? idx : idx + (page * rowsPerPage)) && selectAllFlag || sortyrs[page === 0 ? idx : idx + (page * rowsPerPage)] === val && selectedCheck.includes(page === 0 ? idx : idx + (page * rowsPerPage)) && selectedYear === 'Select' && selectAllFlag || selectedCheck.includes(page === 0 ? idx : idx + (page * rowsPerPage)) && val === selectedYear  || val === selectedYear && selectAllFlag === false && selectedCheck.includes(page === 0 ? idx : idx + (page * rowsPerPage)) ||  selectedYear === 'Select' && selectAllFlag === false && selectedCheck.includes(page === 0 ? idx : idx + (page * rowsPerPage)) && sortyrs[page === 0 ? idx : idx + (page * rowsPerPage)] === val ? '#0076BD' : '#D5E9F8',
            width: 50, height: 50, position: 'relative', borderRadius: '50%', display: 'flex', justifyContent: 'center', 
            alignItems: 'center',border: '0.90px #063669 solid',marginLeft: val === 'Withdraw' ? '20px' : ''}}
            disabled={isDisabled}>
            <span style={{ color: 'black', fontSize: val === 'Withdraw' || val === 'Eligible' ? '10px' : '16px' , fontFamily: 'Source Sans Pro', fontWeight: val === 'Withdraw' || val === 'Eligible' ? '600' : '400' }}>{val}</span>
            </button>
            ))}                                                                                                                                                                                                                                             
          </div>         
            </div>
            </div>
   
           </TableCell>
              : 
          <TableCell>{candidate.certificationStatus}</TableCell>
        }     
          
          <TableCell style={{ textAlign: "left" }}>
          {candidate.resultStatus !== "Achieved" ? 
            <Tooltip 
            title={candidate.ineligiblereason ? 
              reasons.map((reason, index) => {
                return (
                    reason !== "Candidate has results pending" ? 
                  <div>
                <strong>{reason}:</strong><br />
                {handleReasonDesc(reason)}
              </div> : 
              <div>
                <strong>{reason}</strong>
              </div>
                 )})
              : '-'} 
             arrow placement="right" 
              classes={{ tooltip: tooltipClasses.customTooltip , arrow: tooltipClasses.customArrow }}
            >
              {getStatusStyle(candidate?.resultStatus)}
            </Tooltip>
            :
            getStatusStyle(candidate?.resultStatus)
          }
        </TableCell>
        </TableRow>
      );
    })
  );

  const tableDataContentNo=
  (
    <TableRow>
      <TableCell colSpan={12}>
        <Typography
          variant="subtitle1"
          className="text-center py-3"
        >
          No Results Found
        </Typography>
      </TableCell>
    </TableRow>
  );

  const renderTable=()=>{
    return tableData.length > 0 ?  tableDataContent : tableDataContentNo
  }

  return (
    <>
      <div className="pt-5">
     {state.selectedOrg.role === "TEACHER" && dataCheck?.length !== 0 ? <TeacherCheckPoint/> :'' }
     {state.selectedOrg.role === "EXAMS_OFFICER" && dataCheck?.length !== 0 ? 
        
        Eoflag ? <EoCheckpointModal /> : <Notification
         closeable={true}
         size="small"
         title=""
         style={{ margin: 8 }}
         onClose={() => {
           notificationRef.current.className = "d-none";
         }}
       >
         <div className="notification-text">
         Estimated completion date declaration has been submitted by {state.userInfo.email} on {DateConv()}
        </div>
     </Notification>   :  ''
    } 
      {ineligibleActive && isValidCheckpointDate(ineligibleDates.ineligibleStartDate, ineligibleDates.ineligibleEndDate) && ineligibleDataCount.length > 0 ? (
          <div ref={alertRef}>
        <Notification
            closeable={true}
            size="small"
            style={{ margin: 8, color: '#BD4F00', backgroundColor:'#FCF8F3', border:'1px solid #BD4F00' ,textAlign:'left'}}
            onClose={() => {
              alertRef.current.className = "d-none";
            }}
            variant="warning"
          >
          <div className="title" >
          <b>Warning: Candidates at risk of not receiving a qualification result</b>
            <div className="notification-text" style={{marginTop:6}}>
              There are <b>{ineligibleNumberCount()} </b> candidates with an estimated completion date of {academicYear} that are currently not eligible for a result
            </div>
            <div>
              <Button onClick={() => handleAlert() } style={{backgroundColor: "#bd4f00",
            padding: "12px 12px",marginTop:10 ,cursor:'pointer'}}>View candidates</Button>
              </div>
            </div>
          </Notification>
          </div>
        ) 
        : null }     
    </div>
      <div className="my-1 ps-3 pb-2 pt-4 text-start">
        <h4>{candidateListPageLabels.pageTitle}</h4>
      </div>
      <div className="my-1 ps-3 pb-1 text-start">
        <span className="typography-medium18-lato-bold">
          <Typography variant="body1" pb={3}>
            The list of units with their GLH and rules for the award of the
            qualification can be found in the{" "}
            <a
              href="https://www.ocr.org.uk/Images/556921-cambridge-technicals-entry-codes.pdf"
              target="_blank"
              className="text-decoration-none"
              rel="noreferrer"
            >
              OCR Entry Codes booklet
            </a>
          </Typography>
          {state.appr ?
  <Dialog open ={open} onClose = {handleClosePop} maxWidth = "md" 
  PaperProps = {{style:{position: 'absolute',top:'50%',left:'50%',width:'70%',transform:'translate(-50%,-50%)'}}}>
  <Box>
  <Typography id="modal-modal-title" variant="h6" component="h2">
  <div style={{display:'flex',alignItems:'center'}}>  
  <center><span style ={{paddingLeft:'10px'}}><b>Checkpoint Status</b></span></center>
  <Link style={{color: 'black'}}><CloseIcon style={{marginLeft : selected.length > 0 ? '770px' : '770px'}} onClick = {() => {handleClosePop()}}/></Link>
   </div>
   <Notification
            size="small"
            style={{ margin: 8 }}
          >
           { subject.length > 0 ? <div className = "title">
            <b>Term-time checkpoint status: Estimated completion date declaration</b>
            <br /><br />
            <div className="notification-text">
            By checking the box you are confirming that the data for the subject and level has been checked and all candidates completing their course this year have an estimated completion date of Summer {currentyr}
            <br /><br />
           <div> Note: Submitting the declaration to OCR requires the exams officer role.</div>
           </div>
          </div> : <div><b>Term-time checkpoint status: No candidates with an estimated completion date of Summer {currentyr}</b>
<br /><br />
By submitting this declaration, you are confirming no candidates are expecting to complete a qualification for any subjects this summer.
<br /><br />
If candidates are expecting a qualification result this summer, the estimated completion date must be amended to Summer {currentyr}.
<br /><br />
Note: Submitting the declaration to OCR and amending the estimated completion date requires the exams officer role.</div>}
        </Notification>
  </Typography>
  <TableContainer component={Paper}>
  <Table  aria-label="simple table">
  <TableHead color="primary">
    <TableRow>
      <TableCell>Subject</TableCell>
      <TableCell>
        <div className = {classes.checkboxCell}>
        <span> Estimated completion date checked</span> 
      </div>
      </TableCell>
      <TableCell >Checked By</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {tab.map((row,idx) => (
      <TableRow
      >
        <TableCell>{row.subjectlevel}</TableCell>
        <TableCell><Checkbox 
        style ={{width:'40px',marginLeft:'70px'}}
        classes={{
          root: classes.checkbox,
        }}
        checked = {selected.includes(row.subjectlevelcode)} 
        onChange = {() => handleCheckBox(idx,row.subjectlevelcode,row.subjectlevel)}/></TableCell>
        <TableCell style={{width: '400px'}}>
        {manualSelection.includes(row.subjectlevelcode) ?
        `${user} on ${tstamp}` : selected.includes(row.subjectlevelcode) ? 
          `${point.find((item) => item.code === row.subjectlevelcode)?.updated} on ${point.find((item) => item.code === row.subjectlevelcode)?.time}` : '' }</TableCell>
      </TableRow>
    ))} 
 </TableBody>
    </Table>
    {err ? <Stack sx={{ width: '100%' }} spacing={2}>
    <Alert severity="error">{err}</Alert></Stack> : ''}
    <center style = {{padding: '10px'}}>Status :  <span style ={{color: theme.palette.primary.main}}><b>{renderStatus(selected,tab)} </b></span> </center>
    <center><Button onClick ={() => handleSubmit()}>Save</Button></center>
    </TableContainer>
    </Box>
    </Dialog>
    : ''}
    {state.eoappr ?
      <Dialog open ={open} onClose = {handleClose} maxWidth = "md" 
      PaperProps = {{style:{position: 'absolute',top:'50%',left:'50%',width:'70%',transform:'translate(-50%,-50%)'}}}>
      <Box>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      <div style={{display:'flex',alignItems:'center'}}>  
      <center><span style ={{paddingLeft:'10px'}}><b>Checkpoint Status</b></span></center>
      <Link style={{color: 'black'}}> <CloseIcon style={{marginLeft : selected.length > 0 ? '775px' : '775px'}} onClick = {() => {handleClosePop()}}/></Link>
       </div>
       <Notification
                size="small"
                style={{ margin: 8 }}
              >
              {subject.length > 0 ? <div className = "title">
                <b>Term-time checkpoint status: Estimated completion date declaration</b>
                <br /><br />
                <div className="notification-text">
                By checking the box you are confirming that the data for the subject and level has been checked and all candidates completing their course this year have an estimated completion date of Summer {currentyr}
                <br /><br />
               <div> Note: Submitting the declaration to OCR requires the exams officer role.</div>
               </div>
              </div> : <div><b>Term-time checkpoint status: No candidates with an estimated completion date of Summer {currentyr}</b>
<br /><br />
By submitting this declaration, you are confirming no candidates are expecting to complete a qualification for any subjects this summer.
<br /><br />
If candidates are expecting a qualification result this summer, the estimated completion date must be amended to Summer {currentyr}.
<br /><br />
Note: Submitting the declaration to OCR and amending the estimated completion date requires the exams officer role.</div>}
              </Notification>
      </Typography>
      <TableContainer component={Paper}>
      <Table  aria-label="simple table">
      <TableHead color="primary">
        <TableRow>
          <TableCell>Subject</TableCell>
          <TableCell>
            <div className = {classes.checkboxCell}>
            <span> Estimated completion date checked</span>
          </div>
          </TableCell>
          <TableCell>Checked By</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {tab.map((row,idx) => (
          <TableRow
          >
            <TableCell>{row.subjectlevel}</TableCell>
            <TableCell><Checkbox 
            style ={{width:'40px',marginLeft:'70px'}}
            classes={{
              root: classes.checkbox,
            }}
            checked = {selected.includes(row.subjectlevelcode)} 
            onChange = {() => handleCheckBox(idx,row.subjectlevelcode,row.subjectlevel)}/></TableCell>
            <TableCell>
            {manualSelection.includes(row.subjectlevelcode) ?
            `${user} on ${tstamp}` : selected.includes(row.subjectlevelcode) ? 
              `${point.find((item) => item.code === row.subjectlevelcode)?.updated} on ${point.find((item) => item.code === row.subjectlevelcode)?.time}` : '' }</TableCell>
          </TableRow>
        ))} 
     </TableBody>
        </Table>
        <center style = {{padding: '10px'}}>Status : <span style ={{color: theme.palette.primary.main}}><b>{renderStatus(selected,tab)}</b></span> </center>
    <div style={{display:'flex',justifyContent: 'center',gap:'35px'}}>
      <Button  onClick ={() => handleSubmit()}>Save</Button>
      <Button  disabled = {renderStatus(selected,tab) === "Ready to submit" ? false : true} onClick ={() => handleEoSubmit()}>Submit</Button>
    </div>
        </TableContainer>
        </Box>
        </Dialog>
        : ''}
    
          <p>{candidateListPageLabels.pageSubtitle}</p>
          <Tabs value={state.tabValue} onChange={handleTabChange}>
            <Tab value="1"
              onClick={() => {
                dispatch(setSummaryViewActive(true))
                dispatch(setDetailViewActive(false))
                dispatch(setIneligibleViewActive(false))
                dispatch(setAlertClick(false))
              }}
              label={<TabLabel label="Result summary view" />}
            />
            <Tab value="2"
              onClick={() => {
                dispatch(setSummaryViewActive(false))
                dispatch(setDetailViewActive(true))
                dispatch(setIneligibleViewActive(false))
                dispatch(setAlertClick(false))
              }}
              disabled={props.detailedView&&props.detailedView.length===0}
              label={<TabLabel label="Result detailed view" />}
            />
            { isValidCheckpointDate(ineligibleDates.ineligibleStartDate, ineligibleDates.ineligibleEndDate) && ineligibleActive ? (
           <Tab value="3"
             onClick={() => {
              dispatch(setSummaryViewActive(false))
               dispatch(setDetailViewActive(false));
               dispatch(setIneligibleViewActive(true));
              }}
               disabled={props.ineligibleView && ineligibleNumberCount() === 0}
               label={<TabLabel label="Candidates at risk" />}
            />
              ) : null}
          </Tabs>
        </span>
      </div>
      {state.detailViewActive ? (<DetailedView detailedView={props.detailedView} />) :  
       (state.ineligibleViewActive ? (<IneligibleCandidatesView ineligibleView={ state.alertClick ? ineligibleDataCount : props.ineligibleView} getSummary = {getIneligibleSummary} outprops ={outletProps}/>) 
       : (<div className="">
          <div className="download-csv-button py-1" style={{display:'flex',alignItems:'center'}}>
        <Button
          color="primary"
          onClick={() => onDownloadClick()}
          size="large"
          style={{
            margin: 8,
            display:'inline-block',
            height:'auto'
          }}
          variant="contained"
          className="px-3"
        >
          {candidateListPageLabels.downloadCsv}
        </Button>
          {state.selectedOrg.role === 'EXAMS_OFFICER' ?    
         <div style={{display:'flex',alignItems:'center',marginLeft: '59%'}}>
        <Switch  className = {classes.switch}color="primary" variant="contained" checked = {isSwitchOn} onChange={() => handleSwitchChange()} {...label}>
        </Switch> 
        <div style ={{ marginRight: 16 }}>Bulk date change</div> 
        </div> 
         : '' } 
        
       {flag ? 
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedYear}
          label="Year"
          onChange={(e) => handleDropdownChange(e)}
          style={{
            marginRight: 8,
            width: 130,
            height:50,
          }}
        >

         <MenuItem value="Select" style={{display : 'none'}}>Change to</MenuItem>
         <MenuItem value="2024">{(new Date().getFullYear())}</MenuItem>
         <MenuItem value="2025">{(new Date().getFullYear()) + 1}</MenuItem>
          <MenuItem value="2026">{(new Date().getFullYear()) + 2}</MenuItem>
          <MenuItem value="2027">{(new Date().getFullYear()) + 3}</MenuItem>
          <MenuItem value="Withdraw">Withdraw</MenuItem>
        </Select> : ""}
      {flag ? <Button
          color="primary"
          onClick={() => onMulltipleUpdateClick()}
          size="large"
          style={{
            margin: 8,
            display:'inline-block',
            
          }}
          variant="contained"
          className="px-2"
        >
          Apply Changes
        </Button> : ""}
        </div>
         <Table aria-label="simple table" className="table table-responsive mb-0 border-bottom">
              <EnhancedTableHead order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={tableHeaders}
              flagCheck = {flag}
              onSelectAllChange ={handleSelectAllChange}
              selectAll ={selectAll}/>
              <TableBody>
                {renderTable()}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} 
              </TableBody>  
            </Table>
          <TablePagination
            component="div"
            count={tableData.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[50, 100, 150, 200]}
          />
        </div>
      ))}
      <ConfirmationPop 
       EligileModal = {eModal}
       EligibleCancel = {handleEligibleCancel}
       EligibleConfirm = {handleEligibleConfirm}
       Modal = {openModal}
       handleWithdraw = {handleWithdrawCancel}
       handleConfirm = {handleWithdrawConfirm}
       handleCancel = {handleWithdrawCancel}
      />       
</>
  );
  return <>{renderComponentBody()}</>;
};

export default CustomCandidateTable;